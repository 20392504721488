import { useTablet } from '@ee-monorepo/shared/utilities/hooks';
import cn from 'classnames';
import { richTextSerializer } from '@ee-monorepo/prismic/shared/serializers';
import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import { ImageWrapper, SliceWrapper } from '@ee-monorepo/prismic/shared/ui';
import { getColor } from '@ee-monorepo/shared/utilities/functions';
import s from './grid-images.module.scss';
import { PrismicRichText } from '@prismicio/react';

export interface GridImagesProps extends PrismicFields.SliceBase {
  slice?: {
    items?: [
      {
        description1?: PrismicFields.RichTextField;
        header_title?: PrismicFields.RichTextField;
        image?: PrismicFields.Image;
        image_mobile?: PrismicFields.Image;
        background_color?: PrismicFields.Select;
      }
    ];
    primary?: {
      title1?: PrismicFields.RichTextField;
    };
  };
}

export function GridImages({
  index,
  slice: { items, primary },
}: GridImagesProps) {
  const isMobile = useTablet();
  return (
    <SliceWrapper data-testid="gridImages" data-slice-index={index}>
      <div className="snap-container-no-pad">
        <div
          className="text-SnapDarkBlue text-center mb-6 md:mb-9 px-4"
          data-testid="gridImages-title"
        >
          <PrismicRichText
            field={primary?.title1}
            components={richTextSerializer}
          />
        </div>
        <div className="row mx-0">
          {items?.map((item: any, index: number) => {
            const gridWrappper = cn(
              'xs:w-full',
              'p-0',
              'my-0',
              {
                'md:w-5/12': index === 0 || index === 3,
                'md:w-7/12': index === 1 || index === 2,
              },
              { 'md:mt-8': index === 2 || index === 3 }
            );
            const itemClass = cn(
              'pt-8',
              'md:pt-10',
              'h-full',
              getColor(item?.background_color).backgroundColorClass,
              {
                [s.borderTopRight]: index === 1 || index === 3,
                [s.borderTopLeft]: index === 0 || index === 2,
                'pb-8': index !== 3,
                'md:pb-10': index !== 3,
                'md:mr-[2rem]': index === 0 || index === 2,
              }
            );
            const imageClass = cn(
              'flex',
              'items-center',
              'justify-center',
              'sm:px-4',
              'md:px-6'
            );
            return (
              <div
                className={gridWrappper}
                key={index}
                data-testid="gridImageItem"
              >
                <div className={itemClass}>
                  <div className="sm:px-4 md:px-6">
                    <div
                      data-testid="gridImageItem-title"
                      className="text-SnapDarkBlue pb-2"
                    >
                      <PrismicRichText
                        field={item?.header_title}
                        components={richTextSerializer}
                      />
                    </div>
                    <div
                      data-testid="gridImageItem-description"
                      className="pb-0 md:pb-6 text-SnapGrey500"
                    >
                      <PrismicRichText
                        field={item?.description1}
                        components={richTextSerializer}
                      />
                    </div>
                  </div>
                  <div className={imageClass}>
                    <ImageWrapper
                      className={s.minWidth}
                      alt="icon"
                      src={
                        isMobile ? item?.image_mobile?.url : item?.image?.url
                      }
                      width={
                        isMobile
                          ? item?.image_mobile?.dimensions?.width
                          : item?.image?.dimensions?.width
                      }
                      height={
                        isMobile
                          ? item?.image_mobile?.dimensions?.height
                          : item?.image?.dimensions?.height
                      }
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </SliceWrapper>
  );
}
